<template>
  <div>
    <div class="card p-1">
      <div class="row">
        <div class="col-md-6 mb-1">
          <div>
            <label for="id1">ช่องที่ 1 ระบุจำนวนเงิน</label>
            <input
              v-model="wheel1"
              type="number"
              class="form-control"
            >
          </div>
        </div>
        <div class="col-md-6 mb-1 mb-1">
          <div>
            <label for="id1">ช่องที่ 1 ระบุเปอร์เซนต์</label>
            <b-input-group class="input-group-merge">
              <b-form-input
                v-model="wheel1"
                type="tel"
              />
              <b-input-group-append is-text>
                %
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>

        <div class="col-md-6 mb-1">
          <div>
            <label for="id1">ช่องที่ 2 ระบุจำนวนเงิน</label>
            <input
              v-model="wheel1"
              type="number"
              class="form-control"
            >
          </div>
        </div>
        <div class="col-md-6 mb-1">
          <div>
            <label for="id1">ช่องที่ 2 ระบุเปอร์เซนต์</label>
            <b-input-group class="input-group-merge">
              <b-form-input
                v-model="wheel1"
                type="tel"
              />
              <b-input-group-append is-text>
                %
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>

        <div class="col-md-6 mb-1">
          <div>
            <label for="id1">ช่องที่ 3 ระบุจำนวนเงิน</label>
            <input
              v-model="wheel1"
              type="number"
              class="form-control"
            >
          </div>
        </div>
        <div class="col-md-6 mb-1">
          <div>
            <label for="id1">ช่องที่ 3 ระบุเปอร์เซนต์</label>
            <b-input-group class="input-group-merge">
              <b-form-input
                v-model="wheel1"
                type="tel"
              />
              <b-input-group-append is-text>
                %
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>

        <div class="col-md-6 mb-1">
          <div>
            <label for="id1">ช่องที่ 4 ระบุจำนวนเงิน</label>
            <input
              v-model="wheel1"
              type="number"
              class="form-control"
            >
          </div>
        </div>
        <div class="col-md-6 mb-1">
          <div>
            <label for="id1">ช่องที่ 4 ระบุเปอร์เซนต์</label>
            <b-input-group class="input-group-merge">
              <b-form-input
                v-model="wheel1"
                type="tel"
              />
              <b-input-group-append is-text>
                %
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>

        <div class="col-md-6 mb-1">
          <div>
            <label for="id1">ช่องที่ 5 ระบุจำนวนเงิน</label>
            <input
              v-model="wheel1"
              type="number"
              class="form-control"
            >
          </div>
        </div>
        <div class="col-md-6 mb-1">
          <div>
            <label for="id1">ช่องที่ 5 ระบุเปอร์เซนต์</label>
            <b-input-group class="input-group-merge">
              <b-form-input
                v-model="wheel1"
                type="tel"
              />
              <b-input-group-append is-text>
                %
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>

        <div class="col-md-6 mb-1">
          <div>
            <label for="id1">ช่องที่ 6 ระบุจำนวนเงิน</label>
            <input
              v-model="wheel1"
              type="number"
              class="form-control"
            >
          </div>
        </div>
        <div class="col-md-6 mb-1">
          <div>
            <label for="id1">ช่องที่ 6 ระบุเปอร์เซนต์</label>
            <b-input-group class="input-group-merge">
              <b-form-input
                v-model="wheel1"
                type="tel"
              />
              <b-input-group-append is-text>
                %
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>

        <div class="col-md-6 mb-1">
          <div>
            <label for="id1">ช่องที่ 7 ระบุจำนวนเงิน</label>
            <input
              v-model="wheel1"
              type="number"
              class="form-control"
            >
          </div>
        </div>
        <div class="col-md-6 mb-1">
          <div>
            <label for="id1">ช่องที่ 7 ระบุเปอร์เซนต์</label>
            <b-input-group class="input-group-merge">
              <b-form-input
                v-model="wheel1"
                type="tel"
              />
              <b-input-group-append is-text>
                %
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>

        <div class="col-md-6 mb-1">
          <div>
            <label for="id1">ช่องที่ 8 ระบุจำนวนเงิน</label>
            <input
              v-model="wheel1"
              type="number"
              class="form-control"
            >
          </div>
        </div>
        <div class="col-md-6 mb-1">
          <div>
            <label for="id1">ช่องที่ 8 ระบุเปอร์เซนต์</label>
            <b-input-group class="input-group-merge">
              <b-form-input
                v-model="wheel1"
                type="tel"
              />
              <b-input-group-append is-text>
                %
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>

        <div class="col-md-6 mb-1">
          <div>
            <label for="id1">ช่องที่ 9 ระบุจำนวนเงิน</label>
            <input
              v-model="wheel1"
              type="number"
              class="form-control"
            >
          </div>
        </div>
        <div class="col-md-6 mb-1">
          <div>
            <label for="id1">ช่องที่ 9 ระบุเปอร์เซนต์</label>
            <b-input-group class="input-group-merge">
              <b-form-input
                v-model="wheel1"
                type="tel"
              />
              <b-input-group-append is-text>
                %
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>

        <div class="col-md-6 mb-1">
          <div>
            <label for="id1">ช่องที่ 10 ระบุจำนวนเงิน</label>
            <input
              v-model="wheel1"
              type="number"
              class="form-control"
            >
          </div>
        </div>
        <div class="col-md-6 mb-1">
          <div>
            <label for="id1">ช่องที่ 10 ระบุเปอร์เซนต์</label>
            <b-input-group class="input-group-merge">
              <b-form-input
                v-model="wheel1"
                type="tel"
              />
              <b-input-group-append is-text>
                %
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>

        <div class="col-md-6 mb-1">
          <div>
            <label for="id1">ช่องที่ 11 ระบุจำนวนเงิน</label>
            <input
              v-model="wheel1"
              type="number"
              class="form-control"
            >
          </div>
        </div>
        <div class="col-md-6 mb-1">
          <div>
            <label for="id1">ช่องที่ 11 ระบุเปอร์เซนต์</label>
            <b-input-group class="input-group-merge">
              <b-form-input
                v-model="wheel1"
                type="tel"
              />
              <b-input-group-append is-text>
                %
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>

        <div class="col-md-6 mb-1">
          <div>
            <label for="id1">ช่องที่ 12 ระบุจำนวนเงิน</label>
            <input
              v-model="wheel1"
              type="number"
              class="form-control"
            >
          </div>
        </div>
        <div class="col-md-6 mb-1">
          <div>
            <label for="id1">ช่องที่ 12 ระบุเปอร์เซนต์</label>
            <b-input-group class="input-group-merge">
              <b-form-input
                v-model="wheel1"
                type="tel"
              />
              <b-input-group-append is-text>
                %
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>

        <div class="col-md-6 mb-1">
          <div>
            <label for="id1">ช่องที่ 13 ระบุจำนวนเงิน</label>
            <input
              v-model="wheel1"
              type="number"
              class="form-control"
            >
          </div>
        </div>
        <div class="col-md-6 mb-1">
          <div>
            <label for="id1">ช่องที่ 13 ระบุเปอร์เซนต์</label>
            <b-input-group class="input-group-merge">
              <b-form-input
                v-model="wheel1"
                type="tel"
              />
              <b-input-group-append is-text>
                %
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>

        <div class="col-md-6 mb-1">
          <div>
            <label for="id1">ช่องที่ 14 ระบุจำนวนเงิน</label>
            <input
              v-model="wheel1"
              type="number"
              class="form-control"
            >
          </div>
        </div>
        <div class="col-md-6 mb-1">
          <div>
            <label for="id1">ช่องที่ 14 ระบุเปอร์เซนต์</label>
            <b-input-group class="input-group-merge">
              <b-form-input
                v-model="wheel1"
                type="tel"
              />
              <b-input-group-append is-text>
                %
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>

        <div class="col-md-6 mb-1">
          <div>
            <label for="id1">ช่องที่ 15 ระบุจำนวนเงิน</label>
            <input
              v-model="wheel1"
              type="number"
              class="form-control"
            >
          </div>
        </div>
        <div class="col-md-6 mb-1">
          <div>
            <label for="id1">ช่องที่ 15 ระบุเปอร์เซนต์</label>
            <b-input-group class="input-group-merge">
              <b-form-input
                v-model="wheel1"
                type="tel"
              />
              <b-input-group-append is-text>
                %
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>

        <div class="col-md-6 mb-1">
          <div>
            <label for="id1">ช่องที่ 16 ระบุจำนวนเงิน</label>
            <input
              v-model="wheel1"
              type="number"
              class="form-control"
            >
          </div>
        </div>
        <div class="col-md-6 mb-1">
          <div>
            <label for="id1">ช่องที่ 16 ระบุเปอร์เซนต์</label>
            <b-input-group class="input-group-merge">
              <b-form-input
                v-model="wheel1"
                type="tel"
              />
              <b-input-group-append is-text>
                %
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>

        <div class="col-md-6 mb-1">
          <div>
            <label for="id1">ช่องที่ 17 ระบุจำนวนเงิน</label>
            <input
              v-model="wheel1"
              type="number"
              class="form-control"
            >
          </div>
        </div>
        <div class="col-md-6 mb-1">
          <div>
            <label for="id1">ช่องที่ 17 ระบุเปอร์เซนต์</label>
            <b-input-group class="input-group-merge">
              <b-form-input
                v-model="wheel1"
                type="tel"
              />
              <b-input-group-append is-text>
                %
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>

        <div class="col-md-6 mb-1">
          <div>
            <label for="id1">ช่องที่ 18 ระบุจำนวนเงิน</label>
            <input
              v-model="wheel1"
              type="number"
              class="form-control"
            >
          </div>
        </div>
        <div class="col-md-6 mb-1">
          <div>
            <label for="id1">ช่องที่ 18 ระบุเปอร์เซนต์</label>
            <b-input-group class="input-group-merge">
              <b-form-input
                v-model="wheel1"
                type="tel"
              />
              <b-input-group-append is-text>
                %
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>

        <div class="col-md-6 mb-1">
          <div>
            <label for="id1">ช่องที่ 19 ระบุจำนวนเงิน</label>
            <input
              v-model="wheel1"
              type="number"
              class="form-control"
            >
          </div>
        </div>
        <div class="col-md-6 mb-1">
          <div>
            <label for="id1">ช่องที่ 19 ระบุเปอร์เซนต์</label>
            <b-input-group class="input-group-merge">
              <b-form-input
                v-model="wheel1"
                type="tel"
              />
              <b-input-group-append is-text>
                %
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>

        <div class="col-md-6 mb-1">
          <div>
            <label for="id1">ช่องที่ 20 ระบุจำนวนเงิน</label>
            <input
              v-model="wheel1"
              type="number"
              class="form-control"
            >
          </div>
        </div>
        <div class="col-md-6 mb-1">
          <div>
            <label for="id1">ช่องที่ 20 ระบุเปอร์เซนต์</label>
            <b-input-group class="input-group-merge">
              <b-form-input
                v-model="wheel1"
                type="number"
              />
              <b-input-group-append is-text>
                %
              </b-input-group-append>
            </b-input-group>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BInputGroupAppend, BFormInput, BInputGroup } from 'bootstrap-vue'

export default {
  components: {
    BInputGroupAppend, BFormInput, BInputGroup,
  },
  data() {
    return {
    }
  },
}
</script>
