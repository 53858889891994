<template>
  <div class="row">
    <div class="col-md-4">
      <wheel-1 />
    </div>
    <div class="col-md-4">
      <wheel-2 />
    </div>
    <div class="col-md-4">
      <wheel-3 />
    </div>
    <div class="col-md-12">
      <button class="btn btn-gradient-dark btn-block">
        <i class="fa fa-save" /> บันทึกข้อมูล
      </button>
    </div>
  </div>
</template>

<script>
import Wheel1 from './card/wheel1.vue'
import Wheel2 from './card/wheel2.vue'
import Wheel3 from './card/wheel3.vue'

export default {
  components: {
    Wheel1,
    Wheel2,
    Wheel3,
  },

}
</script>

<style>

</style>
